const getters = {
    path: state => state.webSite.path,
    singleReports: state => state.consolidation.singleReports,
    GoodsTypeIds: state => state.consolidation.singleReports.map(item => {
       return item.GoodsTypeId
    }),
    internal: state => state.webSite.internal,
    token: state => state.user.token,
    isAuthenticated: state => state.user.isAuthenticated,
    shopID: state => state.webSite.shopID,
    TenantId: state => state.webSite.TenantId,
    userInfo: state => state.user.userInfo,
    Integral: state => state.user.Integral,
    CouponCount: state => state.user.CouponCount,
    goodTypes: state => state.consolidation.goodTypes,
    language: state => state.webSite.language,
    payTypes: state => state.webSite.payTypes,
    history: state => state.webSite.history,
    help: state => state.webSite.help,
    showDialog: state => state.user.showDialog,
    reading: state => state.webSite.reading,
    rightBox: state => state.webSite.rightBox,
    logoUrl: state => state.webSite.logoUrl,
    shopeeModal: state => state.webSite.shopeeModal,
    shop_name: state => state.webSite.shop_name,
    default_language: state => state.webSite.defaultLanguage,
    taxMode: state => state.webSite.taxMode,
    lineWeight: state => state.consolidation.lineWeight,
    lineSize: state => state.consolidation.lineSize

}
export default getters
